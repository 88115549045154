import React from "react";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "HTML5_skill", content: "HTML5", porcentage: "80%", value: "80" },
        { id: "CSS3_skill", content: "CSS3", porcentage: "75%", value: "75" },
        { id: "JavaScript_skill", content: "JavaScript", porcentage: "70%", value: "70" },
        { id: "PHP_skill", content: "PHP", porcentage: "70%", value: "70" },
        { id: "ReactJS_skill", content: "ReactJS", porcentage: "80%", value: "80" },
        { id: "Wordpress_skill", content: "Wordpress", porcentage: "5%", value: "5" }
      ],
      about_me: [
        {
          id: "first-p-about",
          content:
            "Après 25 ans en tant que professionnel de santé (technicien puis manager), je recherche un nouveau challenge professionnel."
        },
        {
          id: "second-p-about",
          content:
            `Autodidacte dans le développement web depuis 18 ans j’ai voulu consolider les bases du codage en suivant la formation “Formation Développeur web Fullstack - JavaScript” qui m’a permis de renforcer mes compétences.`
        },
        {
          id: "third-p-about",
          content:
            "Curieux, dynamique, à l’écoute et aimant les challenges, je souhaite intégrer une entreprise où l’esprit d’équipe, le travail collaboratif sont des notions importantes."
        }
      ]
    };
  }

  render() {
    return (
      <section id="about" className=" background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-12">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h3 className="title-a ">Mon parcours</h3>
                        <div className="line-mf"></div>
                      </div>
                      <p className="lead" >Développeur web autodidacte depuis 2004, j'ai mené en parallèle l'élaboration de projets web et une carrière de manager dans le milieu de la santé.</p>
                      <p className="lead" >Après une “<a href="https://3wa.fr/formations/formation-developpeur-informatique/developpeur-full-stack-javascript-3-mois-temps-plein/" >Formation Développeur web Fullstack - JavaScript</a>” , qui m'a permis de consolider mon expérience, je m'oriente exclusivement vers le développement.</p>
                      <p className="lead" >Curieux, dynamique, à l’écoute, je suis actuellement à la recherche de nouveau challenges.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
