import React from "react";
import cv from "../img/cv.jpg";
import cv_pdf from "../img/CV_Cédric_Mendoza.pdf";

const CV = (props) => {
  return (
    <section id="cv" className=" background">
      <div className="container">
        <div className="col-sm-12">
          <div className="box-shadow-full">
            <h3 className="title-a ">CV</h3>
            <div className="line-mf"></div>
          </div>
        </div>
        <a href={cv_pdf}>
          <img src={cv} alt="CV" className="img-fluid" />
        </a>
      </div>
    </section>

  );
}

export default CV;
