import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import '../libs/styles_timeline.css';
import skill_html from "../img/skills/html-css.png";
import skill_html5 from "../img/skills/html.png";
import skill_ps from "../img/skills/photoshop.png";
import skill_js from "../img/skills/js.png";
import skill_php from "../img/skills/php.png";
import skill_native from "../img/skills/native.png";
import skill_react from "../img/skills/react.png";
import skill_docker from "../img/skills/docker.png";
import skill_suite from "../img/skills/suite.png";

class Competences extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "HTML5_skill", content: "HTML5", porcentage: "80%", value: "80" },
        { id: "CSS3_skill", content: "CSS3", porcentage: "75%", value: "75" },
 //       { id: "Wordpress_skill", content: "Wordpress", porcentage: "5%", value: "5" },
        { id: "JavaScript_skill", content: "JavaScript", porcentage: "60%", value: "60" },
        { id: "PHP_skill", content: "PHP", porcentage: "80%", value: "80" },
        { id: "MySQL_skill", content: "MySQL", porcentage: "70%", value: "70" },
        { id: "ReactJS_skill", content: "ReactJS", porcentage: "50%", value: "50" },
        { id: "ReactNative_skill", content: "React-Native", porcentage: "35%", value: "35" },
        { id: "ReactNative_ps", content: "Photoshop", porcentage: "80%", value: "80" }
      ],
      skills2: [
        { id: "HTML5_skill", content: "Gestion d'équipe", porcentage: "90%", value: "90" },
        { id: "CSS3_skill", content: "Gestion de projet", porcentage: "90%", value: "90" }
      ],
    };

  }



  render() {
    return (
      <section id="competences" className=" background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
              <div className="title-box text-center">
                <h3 className="title-a">Compétences</h3>
                <div className="line-mf"></div>
              </div>
                <div className="row">
                  <div className="col-md-4">
                    
                    <div className="row">
                      <div
                        className="col-sm-6 col-md-5"
                        style={{ margin: "0 auto" }}
                      >
                        <div
                          className="about-img"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            className="img-fluid rounded b-shadow-a"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  <h5 className="title-gauche">Informatiques</h5>
                    <div className="skill-mf">
                      {/* <p className="title-s">Skill</p> */}
                      {this.state.skills.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <span>{skill.content}</span>{" "}
                            <span className="pull-right">
                              {skill.porcentage}
                            </span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <hr />
                  <h5 className="title-gauche">Managériales</h5>
                    <div className="skill-mf">
                      {/* <p className="title-s">Skill</p> */}
                      {this.state.skills2.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <span>{skill.content}</span>{" "}
                            <span className="pull-right">
                              {skill.porcentage}
                            </span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h5 className="title-left">Evolution</h5>
                      </div>
                      <VerticalTimeline>
                        
                        <VerticalTimelineElement
                          date="2005"
                          icon={<img src={skill_html} alt="" className="img-fluid iconz" />}
                          className="vertical-timeline-element--work "
                          contentStyle={{ background: '#0F141C' }}
                          contentArrowStyle={{ borderRight: '7px solid  #0F141C' }}
                        >
                          <h5 className="vertical-timeline-element-subtitle color-d">Découverte de l'HTML et du CSS</h5>
                          <p className="vertical-timeline-element-subtitle color-d">Premiers "sites" tout en dur </p>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                          date="2006"
                          icon={<img src={skill_php} alt="" className="img-fluid" />}
                          className="vertical-timeline-element--work"
                          contentStyle={{ background: '#0F141C' }}
                          contentArrowStyle={{ borderRight: '7px solid  #0F141C' }}
                          iconStyle={{ background: '#fff' }}
                        >
                          <h5 className="vertical-timeline-element-subtitle color-d">Intégration de PHP et MySQL</h5>
                          <p className="vertical-timeline-element-subtitle color-d">Premiers sites dynamiques </p>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                          date="2006"
                          icon={<img src={skill_ps} alt="" className="img-fluid iconz" />}
                          className="vertical-timeline-element--work "
                          contentStyle={{ background: '#0F141C' }}
                          contentArrowStyle={{ borderRight: '7px solid  #0F141C' }}
                        >
                          <h5 className="vertical-timeline-element-subtitle color-d">Prise en main de Photoshop</h5>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                          date="2009"
                          icon={<img src={skill_js} alt="" className="img-fluid" />}
                          className="vertical-timeline-element--work"
                          contentStyle={{ background: '#0F141C' }}
                          contentArrowStyle={{ borderRight: '7px solid  #0F141C' }}
                        >
                          <h5 className="vertical-timeline-element-subtitle color-d">Améliorations en intégrant le Javascript</h5>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                          date="2014"
                          icon={<img src={skill_html5} alt="" className="img-fluid iconz" />}
                          className="vertical-timeline-element--work "
                          contentStyle={{ background: '#0F141C' }}
                          contentArrowStyle={{ borderRight: '7px solid  #0F141C' }}
                          iconStyle={{ background: '#fff' }}
                        >
                          <h5 className="vertical-timeline-element-subtitle color-d">Passage des site en HTML5</h5>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                          date="2020"
                          icon={<img src={skill_react} alt="" className="img-fluid" />}
                          className="vertical-timeline-element--work"
                          contentStyle={{ background: '#0F141C' }}
                          contentArrowStyle={{ borderRight: '7px solid  #0F141C' }}
                          iconStyle={{ background: '#000' }}
                        >
                          <h5 className="vertical-timeline-element-subtitle color-d">Découverte de Node Js et REACT</h5>
                        </VerticalTimelineElement>


                        <VerticalTimelineElement
                          date="2022"
                          icon={<img src={skill_native} alt="" className="img-fluid iconz" />}
                          className="vertical-timeline-element--work "
                          contentStyle={{ background: '#0F141C' }}
                          contentArrowStyle={{ borderRight: '7px solid  #0F141C' }}
                          iconStyle={{ background: '#fff' }}
                        >
                          <h5 className="vertical-timeline-element-subtitle color-d">Formation "Developpeur web Full Stack"</h5>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                          date="2022"
                          icon={<img src={skill_docker} alt="" className="img-fluid" />}
                          className="vertical-timeline-element--work"
                          contentStyle={{ background: '#0F141C' }}
                          contentArrowStyle={{ borderRight: '7px solid  #0F141C' }}
                          iconStyle={{ background: '#fff' }}
                        >
                          <h5 className="vertical-timeline-element-subtitle color-d">Utilisation de Docker</h5>
                        </VerticalTimelineElement>




                        <VerticalTimelineElement
                          iconStyle={{ background: '#fff', color: '#fff' , marginTop: '40px' }}
                          icon={<img src={skill_suite} alt="" className="img-fluid" />}
                        />
                      </VerticalTimeline>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Competences;
