import React from 'react'
import ReactDOM from 'react-dom';
import './App.css';

//import css in order
import 'normalize.css';
import './animate.css';
import 'bootstrap/dist/css/bootstrap.css';
import './img/icons/css/ionicons.css';
import './img/font-awesome/css/font-awesome.css';
import 'lightbox2/dist/css/lightbox.min.css'
import './style.css';

import Preloader from './components/preloader';
import Navbar from './components/navbar.jsx';
import Intro from './components/intro.jsx';
import About from './components/about.jsx';
import Competances from './components/competences.jsx';
import Portfolio from './components/portfolio.jsx';
import CV from './components/cv.jsx';
import Contact from './components/contact.jsx';
import BackToTop from './components/back-top.jsx';
import Footer from './components/includes/Footer.jsx';


import {Routes, Route} from 'react-router-dom'


function App() {


  return (
    <div className="App">
    <React.Fragment>
        <Navbar />
        <div className="main">
        <Intro />
        <About />
        <Portfolio />
        <Competances />
        <CV />
        <Contact />
    </div>
        <BackToTop />
        <Footer />
    </React.Fragment>,
    </div>
  );
}

export default App;
