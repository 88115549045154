import React from "react";

const Intro = (props) => {
    return (
      // <div id="home" className="intro route bg-image " style={{backgroundImage: "url("+bigImage+")"}}>
      <div id="home" className="intro background">
        <div className="intro-content display-table">
          <div className="table-cell">
            <div className="container">
              <p className="intro-subtitle">
                <strong>Cédric Mendoza</strong>
              </p>
               <h1 className="intro-title mb-4">Développeur web</h1>
               <p className="intro-subtitle">
                <strong>Grenoble</strong>
              </p>
             <p className="pt-3">
                <a
                  className="btn btn-primary btn js-scroll px-4"
                  href="#work"
                  role="button"
                >
                  Portfolio
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Intro;
