import React from "react";
//import "./styles.css";
import logo1 from "../img/mendoz.png";
import { HashLink as Link } from 'react-router-hash-link';
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

//import "bootstrap/dist/css/bootstrap.min.css";

const Navbarr = (props) => {
    return (
      <Navbar collapseOnSelect expand="lg"
        className="navbar navbar-b navbar-trans navbar-expand-md fixed-top">
        <a className="navbar-brand js-scroll" href="">
          <img
            src={logo1}
            alt="logo"
            style={{ maxWidth: "75px" }}
          />
        </a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="responsive-navbar-nav justify-content-end">
          <Nav className="mr-auto">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Nav.Link eventKey="1" as={Link} className="nav-link js-scroll" to="#home">
                  <FontAwesomeIcon icon={faHome} />
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link eventKey="2" as={Link} className="nav-link js-scroll" to="#about">
                  A propos
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link eventKey="3" as={Link} className="nav-link js-scroll" to="#work">
                  Réalisations
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link eventKey="4" as={Link} className="nav-link js-scroll" to="#competences">
                  Compétences
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link eventKey="5" as={Link} className="nav-link js-scroll" to="#cv">
                  CV
                </Nav.Link>
              </li>
              <li className="nav-item">
                <Nav.Link eventKey="6" as={Link} className="nav-link js-scroll" to="#contact">
                  Contact
                </Nav.Link>
              </li>
            </ul>

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
export default Navbarr;