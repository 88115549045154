import React from "react";

//import stock
import mascoutelou from "../img/portfolio/mascoutelou.jpg";
import lousgrezes from "../img/portfolio/lousgrezes.jpg";
import radioanatomie from "../img/portfolio/radioanatomie.jpg";
import clairance from "../img/portfolio/clairance.jpg";
import vinsnaturels from "../img/portfolio/vinsnaturels.jpg";
import appli_pdc from "../img/portfolio/appli_pdc.jpg";
import appli_VN from "../img/portfolio/vinnat.jpg";
import covoit from "../img/portfolio/covoit.jpg";
import lelv from "../img/portfolio/lelv.jpg";
import icono from "../img/portfolio/icono.jpg";
import optimisation from "../img/portfolio/optimisation.jpg";
import blank from "../img/portfolio/blank.jpg";

const Portfolio = (props) => {
    return (
      <section id="work" className="portfolio-mf route">
        <div className="container">
          <div className="row">
            <div className="box-shadow-full">
              <div className="title-box text-center">
                <h3 className="title-a">Portfolio</h3>
                <p className="subtitle-a">
                  Quelques uns de mes projets
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="work-box">
                <a href="https://www.radioanatomie.com/" target="_blank" rel="noreferrer">
                  <div className="work-img">
                    <img src={radioanatomie} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">Radioanatomie.com</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            HTML5 CSS3 PHP MySQL 
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>


            <div className="col-md-4">
              <div className="work-box">
                <a href="https://mascoutelou.fr/" data-lightbox="gallery-aguadeluz" target="_blank" rel="noreferrer">
                  <div className="work-img">
                    <img src={mascoutelou} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">Mas Coutelou</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            HTML5 CSS3 Bootstrap 
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>


            <div className="col-md-4">
              <div className="work-box">
                <a href="https://www.vinsnaturels.fr/" data-lightbox="gallery-todo" target="_blank" rel="noreferrer">
                  <div className="work-img">
                    <img src={vinsnaturels} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">Vinsnaturels.fr</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            PHP MySQL HTML5 CSS3 React (Back)
                          </span>
                          {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>


            <div className="col-md-4">
              <div className="work-box">
                <a href="https://lousgrezes.com/" data-lightbox="gallery-medlingos" target="_blank" rel="noreferrer">
                  <div className="work-img">
                    <img src={lousgrezes} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">Lous Grezes</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            HTML5 CSS3 Bootstrap
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>


            <div className="col-md-4">
              <div className="work-box">
                <a href="https://clairance-creatinine.fr/" data-lightbox="gallery-smth" target="_blank" rel="noreferrer">
                  <div className="work-img">
                    <img src={clairance} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                      <h2 className="w-title">Calcul de la Clairance</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            PHP MySQL HTML5 CSS3 / REACT (V2)
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>


            <div className="col-md-4">
              <div className="work-box">
                  <div className="work-img">
                    <img src={appli_pdc} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">Opti Dose</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            React Native, Expo
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-more-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="work-box">
                  <div className="work-img">
                    <img src={appli_VN} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">Vin Nat' l'appli du site vinsnaturels.fr</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            React Native, Redux, Expo
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-more-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="work-box">
                  <div className="work-img">
                    <img src={icono} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">Illustrations pour revues médicales  </h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Photoshop, Image Ready
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-more-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="work-box">
                <a href="https://clairance-creatinine.fr/Oncologie" data-lightbox="gallery-smth" target="_blank" rel="noreferrer">
                  <div className="work-img">
                    <img src={optimisation} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                      <h2 className="w-title">Outil d'optimisation de dose</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            REACT, NodeJS, MySQL
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-4">
              <div className="work-box">
                  <div className="work-img">
                    <img src={covoit} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">Module de covoiturage </h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                          REACT, Redux, NodeJS, MySQL
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-more-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="work-box">
                  <div className="work-img">
                    <img src={lelv} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">E-commerce Lyre Entre Les Vignes </h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                          Wordpress, Woocommerce
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-more-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                  <div className="work-img">
                    <img src={blank} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-10">
                        <h2 className="w-title">D'autres projets en cours... </h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                          
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="w-like">
                          <span className="ion-ios-more-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>


          </div>
        </div>
      </section>
    );
  }

export default Portfolio;
