import React from 'react'
import ReactGA from 'react-ga4'
import { Link  } from 'react-router-dom'

const Footer = (props) => {

    if(!process.env.HOST_BD){
        // nous sommes en local
       console.log("Local")
       ReactGA.initialize("G-FELJLN3JR0");
       ReactGA.send("pageview");
    }else{
        // nous sommes en ligne
    ReactGA.initialize("G-FELJLN3JR0");
    ReactGA.send("pageview");
    }


    return (
        <footer>

        </footer>
    )
}
export default Footer